import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation } from 'swiper/modules';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/navigation";
import 'swiper/css/autoplay';
import { useEffect, useState } from "react";
import { getProductAll } from "../axios/products";
import SugarSlide from './SugarSlide';
import { useParams } from 'react-router-dom';

export const SugarSlider = ({lang}) => {
const param = useParams(); 
const [slides, setSlides] = useState([]);
const [sliderInstanse, setSwiperInstance] = useState(null);
const currentSlide = slides.findIndex(elements => elements.id === parseInt(param.id));

useEffect(()=>{
    getProductAll(lang).then((response)=>{
        const data = response['hydra:member']
        //filter lang and remove 13-90-40 - melasees id and remove 12-91-41 - zhom id,  
        const filtredElements  = data.filter(element => {
            return (element.language?.key === lang && element.show && element.id !== 13 && element.id !== 90 && element.id !== 40 && element.id !== 12 && element.id !== 91 && element.id !== 41);
        })
        // console.log(filtredElements);
        setSlides(filtredElements);
    }).catch((err)=>{console.log(err);})
},[lang])

useEffect(()=>{
    if(sliderInstanse) {
        sliderInstanse.slideTo(currentSlide, 1);
    }
},[currentSlide, sliderInstanse])


  return (
    <section className="sugar">
    <div className="container">
    {slides.length > 0 && 
    <Swiper
        direction={'vertical'}
        slidesPerView={1}
        spaceBetween={30}
        grabCursor = {true}
        autoplay = {true}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        initialSlide={currentSlide || 0}
        navigation={true}
        pagination={{
            clickable: true,
            dynamicBullets: true,
            // dynamicMainBullets: 5
            renderBullet: function (index, className) {
                return `<div class="${className}"> 
                        <img src='${process.env.REACT_APP_SERVER_URL + (slides[index].mainMedia !== null ? slides[index].mainMedia.contentUrl : (slides[index].media[0] !== undefined ? slides[index].media[0]?.contentUrl : slides[index].translation.media[0]?.contentUrl))}' 
                        height="100%" width = "100" loading="lazy" alt="sugar" /> 
                </div>`;
            }
        }}  
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
    >
        {slides.map((slide, index)=> {
            return <SwiperSlide key={index}>
                <SugarSlide product={slide} lang={lang}/>
            </SwiperSlide>           
        })}
  </Swiper>}
  </div>
</section>
)}