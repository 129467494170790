const contacts = [
    {
        title:'contacts.producer.radehiv',
        location: 'https://goo.gl/maps/wM6hG7eNxJfyF4jD9',
        email: 'office.p@radsugar.com.ua', 
        emailDecor: ['office.p','@radsugar.com.ua'],
        phone: '380325541110',
        phoneDecor: ['+38',' 032', ' 554-11-10'],
    },
    {
        title:'contacts.producer.chortciv',
        location: 'https://goo.gl/maps/HYQY8eqzQq6omuEc6',
        email: 'office.ch@radsugar.com.ua',
        emailDecor: ['office.ch','@radsugar.com.ua'],
        phone: '380509111010',
        phoneDecor: ['+38',' 050', ' 911-10-10'],
    },
    {
        title:'contacts.producer.zbarah',
        location: 'https://goo.gl/maps/JW2dy76Ww5ZxrTqA6',
        email: 'office.z@radsugar.com.ua',
        emailDecor: ['office.z','@radsugar.com.ua'],
        phone: '',
        phoneDecor: [],
    },
    {
        title:'contacts.producer.koziv',
        location: 'https://goo.gl/maps/U1mFzgT3rcDMVCP17',
        email: 'office.k@radsugar.com.ua',
        emailDecor: ['office.k','@radsugar.com.ua'],
        phone: '',
        phoneDecor: [],
    },
    {
        title:'contacts.producer.horostkiv',
        location: 'https://goo.gl/maps/chXRR1i3CndBoQnm7',
        email: 'office.h@radsugar.com.ua',
        emailDecor: ['office.h','@radsugar.com.ua'],
        phone: '',
        phoneDecor: [],
    },
    {
        title:'contacts.producer.gnidava',
        location: 'https://maps.app.goo.gl/qMbKZaA6dgdfX9LP8',
        email: 'office.g@radsugar.com.ua',
        emailDecor: ['office.g','@radsugar.com.ua'],
        phone: '',
        phoneDecor: [],
    },
];

const allDataFooter = {
     contacts,
};

export default allDataFooter;