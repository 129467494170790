import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getProductList } from "../axios/products";
import useOutsideClick from "./Store/useOutsideClick";

function Header({ lang, location, navigate, translate, handleContactClick, paralax }) {
  const [productList, setProductList] = useState([]);
  const [productsShow, setProductsShow] = useState(false);
  const menuref = useRef(null);

  useEffect(() => {
    let active = false;

    getProductList().then((response) => {
      const data = response["hydra:member"];

      // фільтруємо дані за вибраною мовою
      const filtredLang = data.filter((elements) => {
        return elements.language.key === lang;
      });

      const productData = filtredLang.map((item) => {
        return {
          href: "#",
          img: process.env.REACT_APP_SERVER_URL + "/media/" + item.slug + ".jpg",
          title: item.name,
          submenu: item.products.map((item) => {
            return {
              path: lang + "/clients/sugar/" + item.id,
              title: item.name,
              img:
                process.env.REACT_APP_SERVER_URL +
                (item.mainMedia !== undefined ? item.mainMedia.contentUrl :
                (item.media[0] !== undefined
                  ? item.media[0]?.contentUrl
                  : item.translation.media[0]?.contentUrl)),
              weight:
                item?.attributeItems.find(
                  (elem) => elem.attribute.slug === "weight_" + lang
                )?.name ||
                item.translation?.attributeItems.find(
                  (elem) => elem.attribute.slug === "weight_" + lang
                )?.name,
                show: item.show
            }; // якщо не знайдена вага забираєм вагу з перекладів + lang до вибраної мови
          }).filter(products => products.show) // показати тільки наявні,
        };
      })

      if (!active) {
        setProductList(productData);
      }
    }).catch((err)=>{
      console.log(err);
    });

    return () => {
      active = true;
    };
  }, [lang]);

  useOutsideClick(menuref, () => {
    setProductsShow(false);
  });

  // console.log(productList);

  return (
    <header className="header">
      <div className="container">
        <div className="header-inner">
          <Link className="logo" to={`/${lang}`} 
              onClick={()=> {
                if (paralax !== undefined) {
                  paralax.current.scrollTo(0);
                }
                else {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }
              }}>
            <img src="/img/logo.svg" alt="logo" />
          </Link>
          <div className="header__contacts">
            <Link
              className="header__contacts-power"
              to={"https://radsugar.com/"}
              target="_blank"
            >
              <img src="/img/tsykir.svg" alt="tsykir" />
              <p>{translate("header.power")}</p>
            </Link>
            <div className="header__contacts-box" ref={menuref}>
              <div
                className="header__contacts-box-inner"
                onClick={() => {
                  setProductsShow(!productsShow);
                }}
              >
                <img src="/img/box.svg" alt="prod" />
                <p>{translate("header.products")}</p>
              </div>
              {productList.length > 0 && productsShow && (
                <ul className="header__list">
                  {productList.map((product) => {
                    return (
                      <li className="header__list-first" key={product.id}>
                        <p className="header__list-first-title">
                          {product.title}
                          {/* <span>{product.submenu.length || 0}</span> */}
                          <img src="/img/arrowmenu.svg" alt="arrow" />
                        </p>
                        <div className="header__list-second-outer">
                          <ul className="header__list-second-inner">
                            {product.submenu.map((sub) => {
                              return (
                                <li key={sub.path}>
                                  <Link
                                    to={sub.path}
                                    onClick={() => setProductsShow(false)}
                                  >
                                    <img
                                      src={sub.img}
                                      alt="prod"
                                      width={64}
                                      height={78}
                                    />
                                    <p className="sub-title">{sub.title}</p>
                                    <p className="sub-weight">{sub.weight}</p>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <img src="/img/call-calling.svg" alt="call" onClick={handleContactClick}/>
            <div className="header__lang">
              <div className="current-lang">
                <p>
                  <span>{lang}</span>
                </p>
              </div>
              <div className="lang-list">
                <ul>
                  <li
                    onClick={() => {
                      if(location.pathname.length === 1){
                        navigate('/ua');
                      }
                      else {
                        navigate(location.pathname.replace(`/${lang}`, '/ua'));
                      }
                    }}
                  >
                    ua
                  </li>
                  <li
                    onClick={() => {
                      if(location.pathname.length === 1){
                        navigate('/en');
                      }
                      else {
                        navigate(location.pathname.replace(`/${lang}`, '/en'));
                      }
                    }}
                  >
                    en
                  </li>
                  <li
                    onClick={() => {
                      if(location.pathname.length === 1){
                        navigate('/dn');
                      }
                      else {
                        navigate(location.pathname.replace(`/${lang}`, '/dn'));
                      }
                    }}
                  >
                    dn
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
